import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import Pagination from "../../components/pageProps/shopPage/Pagination";
import ProductBanner from "../../components/pageProps/shopPage/ProductBanner";
import ShopSideNav from "../../components/pageProps/shopPage/ShopSideNav";
import { useServiceContext } from "../../hooks/useService";
import { useSearchContext } from "../../SearchContext";
import { useLocationContext } from "../../LocationContext";

const Shop = () => {
  const { listProductos, categorias, tiendas, getDataInicial, getListaProductos, getBusquedaProducto } = useServiceContext();
  const { searchQuery } = useSearchContext();
  const { location } = useLocationContext();
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [marcas, setMarcas] = useState([]);
  const [lcategorias, setLcategorias] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]);

  const itemsPerPageFromBanner = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
  };

  useEffect(() => {
    getListaProductos();
    getDataInicial();
  }, []);

  useEffect(() => {
    if (searchQuery || selectedSections.length) {
      const objeto = { buscador: searchQuery, secciones: selectedSections };
      getBusquedaProducto(objeto);
    }
  }, [searchQuery, selectedSections]);

  useEffect(() => {
    const cat = categorias;
    setLcategorias(cat.filter(item => item.nivel !== "10"));
    let marcas = [];
    cat.forEach((dt) => {
      if (dt.nivel === "10") {
        marcas = marcas.concat(dt.seccioneshijos);
        setMarcas(dt.seccioneshijos);
      }
    });
  }, [categorias]);

  const toggleSection = (idSeccion) => {
    setSelectedSections((prevSections) => {
      const newSections = prevSections.includes(idSeccion)
        ? prevSections.filter(section => section !== idSeccion)
        : [...prevSections, idSeccion];
      
      if (newSections.length > 4) {
        newSections.shift(); // Remove the oldest element
      }
      
      return newSections;
    });
  };

  const handleSortChange = (sortValue) => {
    if (sortValue === "2" && location.lat !== null && location.lng !== null) {
      const sorted = [...listProductos]
        .filter(producto => producto.ubicacion) // Filtrar productos sin ubicación
        .sort((a, b) => {
          const locationA = formatLocation(a.ubicacion);
          const locationB = formatLocation(b.ubicacion);
          if (!locationA || !locationB) return 0;
          const distanceA = getDistance(location, locationA);
          const distanceB = getDistance(location, locationB);
          return distanceA - distanceB;
        })
        .map(product => ({
          ...product,
          distance: getDistance(location, formatLocation(product.ubicacion))
        }));
      setSortedProducts(sorted);
    } else {
      const productsWithDistance = listProductos.map(product => ({
        ...product,
        distance: product.ubicacion ? getDistance(location, formatLocation(product.ubicacion)) : null
      }));
      setSortedProducts(productsWithDistance);
    }
  };

  const formatLocation = (ubicacion) => {
    if (!ubicacion) return null;
    const [lat, lng] = ubicacion.split(",").map(Number);
    return { lat, lng };
  };

  const getDistance = (loc1, loc2) => {
    if (!loc2 || isNaN(loc2.lat) || isNaN(loc2.lng)) return Infinity;
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Earth radius in km
    const dLat = toRad(loc2.lat - loc1.lat);
    const dLon = toRad(loc2.lng - loc1.lng);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(loc1.lat)) * Math.cos(toRad(loc2.lat)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c * 1000; // Convert to meters
  };

  useEffect(() => {
    const productsWithDistance = listProductos.map(product => ({
      ...product,
      distance: product.ubicacion ? getDistance(location, formatLocation(product.ubicacion)) : null
    }));
    setSortedProducts(productsWithDistance);
  }, [listProductos]);

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Productos" />
      <div className="w-full h-full flex pb-20 gap-10">
        <div className="w-[20%] lgl:w-[25%] hidden mdl:inline-flex h-full">
          <ShopSideNav 
            lstiendas={tiendas} 
            marcas={marcas} 
            categorias={lcategorias} 
            selectedSections={selectedSections} 
            onSelectSection={toggleSection} 
          />
        </div>
        <div className="w-full mdl:w-[80%] lgl:w-[75%] h-full flex flex-col gap-10">
          <ProductBanner itemsPerPageFromBanner={itemsPerPageFromBanner} onSortChange={handleSortChange} />
          <Pagination itemsPerPage={itemsPerPage} productos={sortedProducts} />
        </div>
      </div>
    </div>
  );
};

export default Shop;
