import React from "react";
import Brand from "./shopBy/Brand";
import Category from "./shopBy/Category";
import Color from "./shopBy/Color";

const ShopSideNav = ({ lstiendas, marcas, categorias, selectedSections, onSelectSection }) => {
  return (
    <div className="w-full flex flex-col gap-6">
      <Category 
        categorias={categorias} 
        selectedSections={selectedSections} 
        onSelectSection={onSelectSection} 
      />
      <Color tiendas={lstiendas} />
      <Brand marcas={marcas} />
    </div>
  );
};

export default ShopSideNav;
