import React from "react";

const Badge = ({ text, oferta }) => {
  return (
    <div className={`h-[35px] text-white flex justify-center items-center text-base font-semibold duration-300 cursor-pointer ${oferta ? 'w-[150px] bg-red-500 hover:bg-red-700' : 'w-[92px] bg-primeColor hover:bg-black'}`}>
      {text}
    </div>
  );
};

export default Badge;
