import React, { useState } from "react";
import { motion } from "framer-motion";
import NavTitle from "./NavTitle";

const Color = ({tiendas}) => {
  const [showColors, setShowColors] = useState(true);
  const colors = [
    {
      _id: 9001,
      title: "AutoPartesVerde",
      base: "#1E1E1E",
    },
    {
      _id: 9002,
      title: "RepuestosGrises",
      base: "#1E1E1E",
    },
    {
      _id: 9003,
      title: "TiendaRoja",
      base: "#1E1E1E",
    },
    {
      _id: 9004,
      title: "AutosAmarillos",
      base: "#1E1E1E",
    },
    {
      _id: 9005,
      title: "RepuestosAzules",
      base: "#1E1E1E",
    },
  ];

  return (
    <div>
      <div
        onClick={() => setShowColors(!showColors)}
        className="cursor-pointer"
      >
        <NavTitle title="Filtrar por Tienda" icons={true} />
      </div>
      {showColors && (
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
            {tiendas && tiendas.map((item) => (
              <li
                key={item.idInformacion}
                className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2"
              >
                <span
                  style={{ background: "#1E1E1E" }}
                  className={`w-3 h-3 bg-gray-500 rounded-full`}
                ></span>
                {item.nombre}, {(item.descripcion && item.descripcion.slice(0, 10)) || 'No description'}...
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </div>
  );
};

export default Color;
