import React, { createContext, useContext, useState } from "react";
import {
  ObtenerMenbresias,
  ObtenerNewProductos,
  ObtenerPromProductos,
  ObtenerTopProductos,
  ObtenerInforProducto,
  ObtenerOfertas,
  ObtenerDatainicial,
  ObtenerProductos,
  ObtenerBusquedaProducto,
  ObtenerLoginData
} from "../service/request";

const MyContext = createContext();

const ServiceContextProvider = ({ children }) => {
  const [membresias, setMembresias] = useState([]);
  const [newProducto, setNewProducto] = useState([]);
  const [promProducto, setPromProducto] = useState([]);
  const [topProducto, setTopProducto] = useState([]);
  const [ofertas, setOfertas] = useState([]);
  const [tagProducto, setTagProducto] = useState([]);
  const [productosSimilares, setProductosSimilares] = useState([]);
  const [tiendas, setTiendas] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [listProductos, setListProductos] = useState([]);
  const [informacion, setInformacion] = useState({});
  const [sucProducto, setSucProducto] = useState({});

  const getMembresias = async () => {
    const resp = await ObtenerMenbresias();
    setMembresias(resp.value);
  };

  const getTopProductos = async () => {
    const resp = await ObtenerTopProductos();
    setTopProducto(resp.value);
  };

  const getNewProductos = async () => {
    const resp = await ObtenerNewProductos();
    setNewProducto(resp.value);
  };

  const getPromProductos = async () => {
    const resp = await ObtenerPromProductos();
    setPromProducto(resp.value);
  };

  const getInforProducto = async (idpr) => {
    const resp = await ObtenerInforProducto(idpr);
    setTagProducto(resp.value.tags);
    setSucProducto(resp.value.sucursales);
    setProductosSimilares(resp.value.productos);
  };

  const getOfertas = async () => {
    const resp = await ObtenerOfertas();
    setOfertas(resp.value);
  };

  const getDataInicial = async () => {
    const resp = await ObtenerDatainicial();
    setTiendas(resp.value.tiendas);
    setCategorias(resp.value.categorias);
  };

  const getListaProductos = async () => {
    const resp = await ObtenerProductos();
    setListProductos(resp.value);
  };

  const getloginUser = async (mdata) => {
    const resp = await ObtenerLoginData(mdata);
    setInformacion(resp.value);
  };

  const getBusquedaProducto = async (mdata) => {
    const resp = await ObtenerBusquedaProducto(mdata);
    setListProductos(resp.value);
  };

  const getBusquedaProductoheader = async (mdata) => {
    const resp = await ObtenerBusquedaProducto(mdata);
    setListProductos(resp.value);
  };

  const Reiniciar = () => {
    setMembresias([]);
    setNewProducto([]);
    setPromProducto([]);
    setTopProducto([]);
    setOfertas([]);
    setTiendas([]);
    setCategorias([]);
    setListProductos([]);
    setInformacion({});
  };

  return (
    <MyContext.Provider
      value={{
        membresias,
        newProducto,
        topProducto,
        ofertas,
        promProducto,
        tagProducto,
        productosSimilares,
        categorias,
        tiendas,
        listProductos,
        informacion,
        sucProducto,
        getMembresias,
        getTopProductos,
        getNewProductos,
        getPromProductos,
        getOfertas,
        getInforProducto,
        getDataInicial,
        getListaProductos,
        getBusquedaProducto,
        getBusquedaProductoheader,
        getloginUser,
        Reiniciar,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

const useServiceContext = () => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error("useServiceContext debe ser utilizado dentro de un proveedor ServiceContextProvider");
  }
  return context;
};

export { ServiceContextProvider, useServiceContext };
