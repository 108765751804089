import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  filtroaceite,
  bateria,
  spfOne,
  noviable,
} from "../../../assets/images/index";

const SpecialOffers = ({producto}) => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Oferta Especiales" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
      {producto.map((product) => (
        <Product
          _id={product.idContenido.toString()}  // Convertir idProducto a cadena
          img={product.multimedias.length > 0 && product.multimedias[0].base64 ? `data:image/jpeg;base64, ${product.multimedias[0].base64}` : noviable}
          productName={product.titulo}
          //price={product.precioNormal.toFixed(2)}  // Formatear precioNormal a dos decimales
          oferta={true}
          des={product.descripcion}
        />
      ))}


</div>

    </div>
  );
};

export default SpecialOffers;
