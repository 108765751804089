import React, { useEffect, useState }  from "react";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import BestSellers from "../../components/home/BestSellers/BestSellers";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import Sale from "../../components/home/Sale/Sale";
import SpecialOffers from "../../components/home/SpecialOffers/SpecialOffers";
import YearProduct from "../../components/home/YearProduct/YearProduct";
import { useServiceContext } from "../../hooks/useService";
const Home = () => {
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [error, setError] = useState(null);
  const {newProducto,tagProducto, productosSimilares, topProducto, ofertas, promProducto, getTopProductos, getNewProductos, getPromProductos, getOfertas, getInforProducto}= useServiceContext();

  useEffect(()=>{
   // handleGetLocation();
    getTopProductos();
    getNewProductos();
    getPromProductos();
    getOfertas();
  },[]);

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
          setError(null);
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  };
  return (
    <div className="w-full mx-auto">
      <Banner />
      {/*<BannerBottom />*/}
      <div className="max-w-container mx-auto px-4">
        {/*<Sale />*/}
        <SpecialOffers producto={ofertas} getsimilar={getInforProducto} tagprod={tagProducto} productsim={productosSimilares}/>
        <NewArrivals producto={newProducto} getsimilar={getInforProducto} tagprod={tagProducto} productsim={productosSimilares}/>
        <YearProduct   />
        <BestSellers producto={topProducto} getsimilar={getInforProducto} tagprod={tagProducto} productsim={productosSimilares}/>
      </div>
    </div>
  );
};

export default Home;
